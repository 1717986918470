<template>
  <section class="main-section main-section-advantage">
    <v-img src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/banner/banner-advantage.png"></v-img>
    <div class="main-layout main-layout-advantage" v-for="(a, i) in advantages" :key="i">
      <div class="advantage-section">
        <h3 class="advantage-name-div"><span :class="'advantage-name advantage-name-' + a.name">{{ a.title }}</span></h3>
        <p class="advantage-desc">{{ a.desc }}</p>
        <div class="advantage-page" v-for="(c, ai) in a.content" :key="ai">
          <h3 class="advantage-title">
            <span class="advantage-title-text">{{ c.title }}</span>
            <span class="advantage-title-bg"></span>
          </h3>
          <div class="advantage-content" v-html="c.content"></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    advantages: [
      {
        title: '精准的定制方案',
        name: 'fangan',
        desc: '在玛娅，每⼀套产品永远是量量身定制的，我们永远精准打造和谐舒适的⽣活空间为客户提供更惬意的优质⽣活享受。',
        content: [
          {
            title: '专属化定制',
            content: '<p>我们资深的设计师从⼀张⽩纸开始对客户进⾏访谈。客户的住宅，品味与⽣活习惯——在开始勾画家居产品蓝图之前，这⼀切都将得到缜密考虑，以保证最终产品能够达到与空间和主⼈默契的配合。</p>'
          },
          {
            title: '实现客户诉求',
            content: '<p>不论其家居空间布局如何，也不管住宅的原有建筑⻛格怎样，我们的设计师永远以⼀张空⽩纸以及对可⽤空间和客户意愿的考虑为起点，来开发⼀款个性化的独⼀⽆⼆的产品。也⽆论客户的品位如何，我们都⼒争实现客户诉求。</p>'
          },
          {
            title: '精准的尺⼨',
            content: '<p>⽆论客户的家居空间尺⼨规格如何变化万千，我们永远精准地加⼯家居产品柜体与⼯作台⾯。让家居空间中的每⼀⼨空间都可得到最有效的利⽤，以营造和谐、有序的舒适烹饪环境。</p>'
          },
          {
            title: '定制化产品',
            content: '<p>没有哪间房间可以按照某个特定的⻓宽⾼标准⽽建成，因此玛娅始终严格遵循实际尺⼨制造相匹配的产品，可以保证让不美观的接缝与空余的⻆落统统消失。</p>'
          }
        ]
      },
      {
        title: '个性化创意设计',
        name: 'sheji',
        desc: '⾛近玛娅，你能感受到设计的坚守与美感，每⼀处细节，都蕴含欧洲顶尖的设计视野与格局。玛娅始终坚信现代主义设计理念，以实⽤主义和⻛格化的纯粹设计缔造传世经典，让价值不只停留在产品本身，使定制玛娅橱柜成为对尊贵⽣活品位的⼀次升华。',
        content: [
          {
            title: '⾰新创意',
            content: '<p>玛娅与众多潮流前沿的设计团队进⾏合作，我们不仅仅停驻在空间家居产品设计上，更多是在探索⼀条让居室空间更贴近⽣活本质的途径。玛娅永远在保持新潮、⾰新，⽆论是家居产品的外观还是使⽤的⽅法，还是让空间氛围变得感性、明朗、清新，或是给家居空间注⼊了活⼒，让家⼈尽情享受住宅中的欢聚时光。</p>'
          },
          {
            title: '沉稳⼤⽓',
            content: '<p>在玛娅，我们不单是以产品来填充空间，⽽是更多的兼顾整个居室空间维度。这⼀设计哲学是我们所有设计⽅案的核⼼所在，并指引玛娅家居的设计师们从事每⼀套定制产品的设计⼯作。</p><p>兼顾全局的思维模式意味着在玛娅定制的产品不仅有着独⼀⽆⼆的个性化外观，并符合⼈体⼯学设计理念，还具有满⾜所有房屋空间需求、装饰⻛格的协同性与功能性。</p>'
          }
        ]
      },
      {
        title: '多样的模块选择',
        name: 'xuanze',
        desc: '',
        content: [
          {
            title: '海量的柜型选择',
            content: '<p>在玛娅，我们在全屋系统内数千种柜型尺⼨规格，数百种柜型样式，涵盖家居空间内各种产品尺⼨，即使⾮标准尺⼨的柜体玛娅也会毫⽆障碍的定制。模块化设计与⽣产，满⾜客户⽇益复杂的个性化需求。</p>'
          },
          {
            title: '丰富的产品花⾊',
            content: '<p>玛娅将更多更具个性和潮流的⾊彩应⽤于产品，每⼀种颜⾊都由研发设计团队和⾊彩⼤师精⼼甄选、实验搭配、创意应⽤，让⽤户真正体验到视觉⾊彩美学，⽤不同的花⾊，装扮出独具你个⼈特⾊的家居空间⽓质和美丽⼼情。</p>'
          },
          {
            title: '多样的⾯板材质⾊',
            content: '<p>从双饰板到覆膜，从烤漆到艺术漆，从铝框玻璃到岩板，从⽊⽪、实⽊到⽪⾰，多样化的材质，构建起了玛娅⽴体化的产品结构，也给予了客户更多的选择，⼀次性装扮出⾃⼰更具丰富性的空间。</p>'
          }
        ]
      },
      {
        title: '精湛的匠心工艺',
        name: 'gongyi',
        desc: '独具匠⼼，巧夺天⼯——这是玛娅⼿⼯艺匠师们纯熟技艺的真实写照。精⼼、细致的⼿⼯打磨成就了玛娅精准契合的柜⻔、抽屉和台⾯等产品。同时我们将传统⼯艺与现代先进技术巧妙结合，为⽤户创造⼀个⽆限⽣活乐趣的梦想家居空间。',
        content: [
          {
            title: '传承不息',
            content: '<p>玛娅历经半个世纪所传承下来的精湛⼿⼯⼯艺让我们引以为傲。</p><p>由于创始⼈是⽊匠出身，众多的资深⼿⼯匠师在其学徒时期便在玛娅公司成⻓扎根，今⽇他们把⾃⼰的丰富知识以及⾼超⼿艺⽆私地奉献传授给下⼀代，让玛娅家居对于产品品质的精细化更加严苛和考究。</p>'
          },
          {
            title: '创新不⽌',
            content: '<p>在玛娅所有产品的⽣产过程中，传统⼿⼯技艺与先进的机床技术得到了圆满结合，通过⾼科技的数控切割铣床，始终确保每件产品绝对的精确度，并让玛娅产品饱含⾼级⼿⼯⼯艺的成效。精密的质量控制测试同样肯定每⼀加⼯阶段都达到完美的标准，这样我们可为每⼀客户定制更⾼质量⽔准的家居产品。</p>'
          },
          {
            title: '历久弥新',
            content: '<p>玛娅⽣产的每套产品都是基于个性化订单⽽定制，并在加⼯过程中反复进⾏检测，千锤百炼般的历练确保了玛娅的产品多年后仍旧品质如新。</p><p>选择玛娅产品有着⽆数理由，如琳琅满⽬的⾯板与材料、独具创意的设计、专属定制、严苛质量标准等等。然⽽最终决定性的理由是：玛娅的客户可以全⽅位的⾼枕⽆忧——坚如磐⽯的质量、超⻓使⽤寿命，给您更精致更安⼼的产品体验。</p>'
          }
        ]
      },
      {
        title: '高端的周边配置',
        name: 'peizhi',
        desc: '严选全球知名品牌，为⽤户提供更⾼品质的家居产品和更舒适、美好的产品体验。同时，在中国的定制家居⾏业⾸倡并提出健康、环保家居品质系统从源头开始给客户最安全健康的保障。',
        content: [
          {
            title: '智慧收纳',
            content: '<p>玛娅⼒求在微⼩细节同样满⾜客户空间收纳需求，各种收纳功能五⾦的应⽤，让家居收纳更加的系统、便捷，让每⼀件物品都各归其位，让每⼀个空间死⻆都能得到充分便捷的利⽤，让您⽣活更有条理。</p>'
          },
          {
            title: '强韧灵动',
            content: '<p>玛娅甄选全球知名优质五⾦配件，赋予产品更强⼤的⽣命⼒。奥地利GRASS抽屉系统，三节同步导轨设计，运⾏更加流畅，最⾼称重可达70KG。奥地利BLUM上翻⻔⽓撑，内置 BLUMOTION阻尼，开启轻巧，关闭安静⽆声。意⼤利SALICE铰链，内置集成阻尼，体积更⼩，灵活的3D调节功能，让产品更加强韧耐⽤，灵动⾃如。</p>'
          },
          {
            title: '牢固可靠',
            content: '<p>柜体板卓越的板材厚度，三层芯板设计，中层坚固，有效确保柜体承重，并避免柜体变形。调整脚直径80mm，坚固耐⽤，⽀撑更有⼒，每个调整脚承重最⼤值450KG，能更有效的⽀撑整体橱柜的重量。</p>'
          },
          {
            title: '健康环保',
            content: '<p>玛娅的柜体板材选⽤奥地利EGGER饰⾯板，板材通过欧标E05级环保认证，⽐欧盟执⾏的E1级环保标准还要⾼，甲醛释放量为0.5mg/L，仅为国标的1/3，达到直接⼊住标准，更加绿⾊环保，更好的守护您和家⼈的健康安全！</p>'
          }
        ]
      },
      {
        title: '严格的质量标准',
        name: 'biaozhun',
        desc: '玛娅秉持着“全球化同步⽣产系统”的理念，原材料由德国全球统⼀采购，所有员⼯均通过德国培训， ⽣产流程由德⽅技术⼈员规划及监管，加之与德国同步的先进设备与⼯艺，使得每⼀件产品都是堪⽐『德国制造』。',
        content: [
          {
            title: '材料德国统⼀采购',
            content: '<p>玛娅的原材料和五⾦配件均由德国总部全球统⼀采购，从根本上保证了玛娅的产品品质与德国保持同步，对于原材料质量的严格要求，让玛娅⽣产的每⼀套产品都拥有着堪⽐精美艺术品的优秀品质。</p>'
          },
          {
            title: '同步德国设备⼯艺',
            content: '<p>玛娅中国引进了与德国同步的机床加⼯设备，采⽤德国先进的豪迈数字化全⾃动⽣产线，产品制造过程⾃动化、信息化、精细化三管⻬下，确保每⼀套定制产品从设计、开料、裁切，每道⼯艺层层把关，每个环节精益求精，让玛娅的⽣产更加⾼效。</p>'
          },
          {
            title: '德⽅专业培训监管',
            content: '<p>玛娅的整个⽣产流程由德⽅技术⼈员进⾏规划及监管，同时每⼀个订单通过ERP系统由德国总部审核下发⽣产，使得玛娅⽣产出来的每⼀套产品完全符合德国技术标准，更⾼的技术要求，也让玛娅产品品质在⾏业内更具优势。</p>'
          }
        ]
      },
      {
        title: '完善的售后体系',
        name: 'shouhou',
        desc: '玛娅家居设置了完善的售后服务体系，每⼀位客户都有独⽴的客户档案，能够在更短的响应时间内快速的查询并处理相关售后事务，为客户提供更更⾼效快捷、安⼼⽆忧的服务。',
        content: [
          {
            title: '独⽴客户档案',
            content: '<p>我们建⽴了完善的档案系统，每⼀位客户在玛娅都有完整独⽴的客户档案，保证客户资料及订单信息数据的完整性，让每⼀位客户的订单都能做到有据可查，为我们的售后服务提供基本的信息化保障。</p>'
          },
          {
            title: '更短交货周期',
            content: '<p>基于玛娅国内⼯⼚的本地化优势，令玛娅的交货期⼤⼤的缩短，玛娅产品的交货期仅为45-65天，更短的交货期，让客户能在更短的时间内乔迁新居，更早的享受品质⽣活。</p>'
          },
          {
            title: '快捷⾼效服务',
            content: '<p>玛娅⼯⼚本地化优势，也让玛娅的售后服务更加的快捷⾼效，服务响应时间更短，服务更加的灵活便捷，⽤户的任何问题，我们都能够在更短时间内提供完善的售后解决⽅案，避免了漂洋过海的漫⻓等待时间，给⽤户更贴⼼舒适的服务体验。</p>'
          }
        ]
      },
      {
        title: '专业的色彩搭配',
        name: 'dapei',
        desc: '中国流⾏⾊协会家居⾊彩研究院是玛娅家居战略合作伙伴，使德国玛娅家居时刻⾛在家居⾊彩流⾏前沿，不断推出更具前瞻性和创新性的花⾊及产品，为⽤户提供家居产品配⾊解决⽅案，为客户打造更有装饰性和个⼈特⾊的家居空间。',
        content: [
          {
            title: '宗旨与愿景',
            content: '<p>中国流⾏⾊协会家居⾊彩研究院专注于研究家居⾊彩与设计，秉承先进的国际⾊彩理念与成果，⾯向泛家居领域企业和从业者提供专业的⾊彩服务；⾯向家居全产业链和消费者分享⾊彩与设计资讯，引导更多⼈关注家居⾊彩、享受家居⾊彩之美。正确的颜⾊，让⽣活更美好！</p>'
          },
          {
            title: '创新⾊彩应⽤',
            content: '<p>德国玛娅家居依托家居⾊彩研究院设计师团队，致⼒于产品⾊彩搭配与创新，不断推出更加潮流时尚的家居产品，时刻⾛在家居⾊彩流⾏前沿，打造产品的超⾼颜值，为客户创造更具时尚感和艺术感的家居⽣活空间。</p>'
          }
        ]
      }
    ]
  }),
  created () {
    this.$store.commit('updateActiveNav', null)
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/about.css';
</style>
